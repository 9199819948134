module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":200},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-material-design-for-bootstrap","short_name":"MDB starter","start_url":"/","background_color":"#4B5EB8","theme_color":"#68008C","display":"standalone","icon":"C:\\Users\\nigel\\Documents\\happinest/src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8054ca4c0e87725c2e266590d3e21e51"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
